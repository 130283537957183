.vengage__customCalender__dateCalendar {
    /* display: flex;
    justify-content: center; */
}

.vengage__dateList__monthYear {
    margin-left: 5px;
    margin-bottom: 5px;
    font-weight: bold;
    font-size: 15px;
}

.vengage__customdatepicker {
    /* width: 320; */
    /* background: white; */
    background: rgba(235, 234, 232, 0.644);
    padding-top: 5px;
    padding-bottom: 5px;
    border: 1px solid #119fda;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
    min-height: 295px;
}

.vengage__customdatepicker__oneMonth {
    background: rgba(235, 234, 232, 0.644);
    padding-top: 5px;
    padding-bottom: 5px;


    /* border: 1px solid red; */
    height: 300px;
}

.vengage__customCalendar__button {
    display: flex;
    justify-content: center;
}

.vengage__customCalendar__customButton {
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    user-select: none;
    background-color: #a9d18e;
    border: 1px solid transparent;
    padding: 0.35rem 1rem;
    font-size: 14px;
    line-height: 1.5;
    border-radius: 0.25rem;
    /* flex-grow: 1; */
    margin-left: 4px;
    cursor: pointer;
    margin-right: 4px;
    opacity: 1;
    transition: opacity 0.3s;
}

.vengage__customCalender__selectedDateText {
    display: flex;
    /* justify-content: center; */
    font-size: 17px;
    margin-left: 6px;
    padding: 0px;
}

.vengage_customdatepicker__onemonth {
    height: 372px;

}

@media (max-width: 600px) {
    .vengage__customdatepicker {
        font-size: 12px !important;
        min-height: 295px;
        /* max-height: 400px; */
        border: 1px solid #119fda;


    }

    .vengage__customCalender__dateCalendar {
        font-size: 12px !important;
    }

    /* .datelist__date {
    font-size: 12px;
  } */
    .vengage__dateList__monthYear {
        font-size: 13px;
    }

    /* .datelist__dateActive {
    font-size: 12px;
  } */
}