.react-chatbot-kit-chat-container {
  width: 420px;
}

.react-chatbot-kit-chat-inner-container {
  height: 610px;
}
.react-chatbot-kit-chat-input-container {
  bottom: 30px;
}
.react-chatbot-kit-chat-message-container {
  padding: 0 7.5px 10px 7.5px !important;
  overflow: auto;
  height: 463px;
}

.react-chatbot-kit-chat-header .subtitle {
  display: block;

  padding-top: 0px;
  padding-bottom: 0px;
}
.react-chatbot-kit-user-chat-message-container {
  margin: 0px auto;
  max-width: 90%;
  margin-right: 0;
}
.react-chatbot-kit-user-chat-message {
  background-color: #009fda;
  color: #fff;
}
.react-chatbot-kit-user-chat-message-arrow {
  border-top: 14px solid transparent;
  border-bottom: 0px solid transparent;
  border-left: 14px solid #009fda;
  top: auto;
  bottom: 0;
}

.react-chatbot-kit-chat-bot-message {
  color: #000;
  border: 1px solid #7386a2;
  font-size: 14px !important;
  width: 72%;
  margin-left: 0;
  font-weight: normal !important;
  line-height: normal !important;
}
.react-chatbot-kit-chat-bot-message-arrow {
  border: 10px solid transparent;
  border-top-color: #7386a2;
  top: -1px !important;
  left: -10px !important;
}
.react-chatbot-kit-chat-bot-message::after {
  content: "";
  border: 10px solid transparent !important;
  border-top-color: white !important;
  top: 0px !important;
  position: absolute;
  width: 0;
  height: 0;
  left: -8px;
  right: auto;
  bottom: -20px;
  border-color: white transparent transparent #ffffe000;
}
.react-chatbot-kit-chat-bot-avatar-container {
  background-color: #fff;
  width: auto;
  height: auto;
}
.react-chatbot-kit-chat-btn-send {
  /* display: none; */
  /* background-color: #119FDA;*/
}
.react-chatbot-kit-chat-input {
  font-size: 12px !important;
  border: none;
  background: #fff;
  box-shadow: 0px 0px 5px #00000045;
  border-radius: 0;
  max-width: 85%;
  z-index: 999;
  margin: auto;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  font-style: italic;
}

.react-chatbot-kit-chat-input:focus {
  outline: none;
  box-shadow: 0px 0px 5px #000000a3;
}
.react-chatbot-kit-chat-input:before {
  content: "";
}
.react-chatbot-kit-chat-inner-container ::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

.react-chatbot-kit-chat-header {
  background-color: #009fda;
  color: #fafafa !important;
  display: block;
}

@media (max-width: 600px) {
  .react-chatbot-kit-chat-header .subtitle {
    padding-top: 0px;
    margin-left: 17px !important;
  }

  .react-chatbot-kit-chat-message-container {
    padding-right: unset;
    padding-left: unset;
  }
  .react-chatbot-kit-chat-input {
    padding-left: 27px !important;
  }
  .react-chatbot-kit-chat-bot-message {
    font-size: 12px !important;
  }
  .react-chatbot-kit-chat-container {
    width: 361px;
  }
}
