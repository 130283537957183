.vengage__reappointment {
    position: absolute;
    top: 180px;
    background: #ffffffe6;
    padding: 12px;
    text-align: center;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
  }
  .vengage__restartPopUp {
    border: 1px solid #F8F8F8;
    padding: 10px 10px 30px 10px;
    background-color: #F8F8F8;
  }

  .vengage__reappointment .vengage__options {
    margin: 15px 0;
    position: relative;
    top: 31%;
    transform: translateY(-50%);
  }
  
  .vengage__options__container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 30px;
  }
  .vengage__restart {
  }
  