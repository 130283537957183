.vengage__claendar {
  display: flex;
  justify-content: center !important;
}

.vengage__calendar__day {
  width: auto !important;
  border: 0px solid !important;
}

.vengage__calendar__daysinmonth {
  text-align: center !important;
}

.vengage__week__day {
  font-size: 13px !important;
  font-weight: 400 !important;
  word-break: normal !important;
  overflow-wrap: normal !important;
  border: 0px !important;
  font-weight: 500 !important;
  min-width: 40px;
}

.vengage__slot__notAvialable {
  align-items: center !important;
  cursor: not-allowed !important;
  font-style: italic !important;
  font-size: 11px !important;
  border: 1px solid lightblue;
  padding: 8px 13px !important;
  border-radius: 5px !important;
  margin: 2px !important;
  color: gray;
  background: white;
  width: 18px !important;
  background-color: #f2f1f0;
}

.vengage__datelist__date,
.vengage__datelist__date__selected {
  align-items: center !important;
  border: 1px solid lightblue !important;
  padding: 8px 13px !important;
  border-radius: 5px !important;
  margin: 2px !important;
  background: white;
  cursor: pointer !important;
  width: 18px !important;
  font-size: 13px !important;
  background-color: white;
}

.vengage__claendar__date {
  display: flex;
  justify-content: center;
  align-items: center;
  word-break: normal !important;
  overflow-wrap: normal !important;
}

.vengage__datelist__date:hover {
  background: lightgray !important;
}

@media (max-width: 600px) {
  .vengage__datelist__date {
    font-size: 12px !important;
    padding: 8px 10px 8px 10px !important;
  }

  .vengage__datelist__dateActive {
    font-size: 12px !important;
  }
  .vengage__slot__notAvialable {
    padding: 8px 10px 8px 10px !important;
  }
  .vengage__datelist__date,
  .vengage__datelist__date__selected {
    align-items: center !important;
    border: 1px solid lightblue !important;
    padding: 8px 13px !important;
    border-radius: 5px !important;
    margin: 2px !important;
    background: white;
    cursor: pointer !important;
    width: 18px !important;
    font-size: 13px !important;
  }
}