.doctorAvailablity {
  margin-left: 5px;
  height: 300px;
}
.tab_bar{
  display: flex;
  width: 50%;
  /* background-color:; */
  justify-content: space-evenly;
  /* border-top-left-radius: 8px; */
  /* border-top-right-radius: 8px; */
}
.morningAndNoonslots__buttons {
  display: flex;
  font-size: 14px;
  border-top-left-radius: 10px !important;
  /* border: 1px solid lightgray; */
  border-bottom: none;

  /* border-top: 1px solid; */
  /* border-bottom: 1px solid lightgray; */
  /* justify-content: center; */
  width: 100%;
  /* padding: 5px; */
  border-top-left-radius: 5px;
  border-top-right-radius: 8px;
  /* border-top-right-radius: 8px; */


}

.morning_tab{
  /* border: 1px solid white; */
  /* border-top-left-radius: 8px; */
  /* border-top-right-radius: 12px; */
  border-top: none;
  /* border-left: 1px solid lightgray; */
  /* border-right: 1px solid lightgray; */
  background:  rgba(221, 219, 219, 0.548);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;


  padding-right: 20px;
  padding: 5px;
width: 100%;
display: flex;
justify-content: center;
}

.afternoon_tab{
  padding: 5px;
  width: 100%;
  display: flex;
  justify-content: center;
  /* border-top-right-radius: 5px; */
  /* border-top-left-radius: 12px; */
  background: rgba(221, 219, 219, 0.548);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  /* border: 1px solid white; */
  /* border-top: 1px solid lightgray; */
  /* border-left: 1px solid lightgray; */
  /* border-right: 1px solid lightgray; */


  /* border: 1px solid lightblue;  */

}



.morning_tab:hover, .afternoon_tab:hover{
  cursor: pointer;
  /* background-color: lightblue ; */
  /* border-top: 1px solid lightgray; */
  /* border-left: 1px solid lightgray; */
  /* border-right: 1px solid lightgray; */
}
.morningAndNoonslots__button {
  padding: 5px 20px;
  margin-right: 10px;
  font-size: 15px;
  background-color: white;
  border: 1px solid lightblue;
  outline: none;
  border-radius: 5px;
}
.morningAndNoonslots__button:hover {
  background-color: lightblue;
  color: black;
  cursor: pointer;
}




.time_selection_tab{
  display: flex;
  font-size: 15px;
}