.vengage__options {
  display: flex;
  margin-top: 10px;
  flex-wrap: wrap;
  flex-direction: column;
}

.vengage__option {
  border-radius: 25px;
  padding: 8px;
  border: 1px solid #173e3f;
  color: #1d1d1d;
  font-size: 0.7rem !important;
  margin: 4px 4px;
  background: transparent;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  line-height: normal !important;
}
.vengage__option:hover,
.vengage__option:focus {
  background: #ececec;
}
.vengage__buttonImage {
  width: 40px;
  margin-right: 8px;
}
.vengage__txt {
  font-weight: 700;
  font-size: 16px;
}
.vengage__subtxt {
}

@media (max-width: 600px) {
  .vengage__option {
    font-size: 12px;
  }
  .vengage__txt {
    font-size: 14px;
  }
  .vengage__buttonImage {
    width: 30px;
  }
}
