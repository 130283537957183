.vengage__showDatePickerWithTab {
    /* min-height: 385px; */
    width: 100%;

}

.vengage__tabs {
    /* border: 1px solid lightblue; */
    /* width: 100%; */
    width: 99%;
    margin-left: 5px;
    display: flex;
}

.vengage__customtab {
    border-top: none;
    /* border-left: 1px solid lightgray; */
    /* border-right: 1px solid lightgray; */
    background: rgba(221, 219, 219, 0.548);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;


    padding-right: 20px;
    padding: 5px;
    /* width: 25%; */
    display: flex;
    justify-content: center;
    font-size: 12px;
    border-right: 1px solid rgb(202, 200, 200);
    border-top: 1px solid rgb(202, 200, 200);
    border-left: 1px solid rgb(202, 200, 200);
}

.vengage__selectedTab {
    background-color: rgba(17, 159, 218, 1);
    color: white;


    font-weight: 450;
    border: none;
    /* display: flex;
    flex-wrap: wrap; */
    overflow: hidden;
    text-overflow: ellipsis;



}

.vengage__customtab:hover {
    cursor: pointer;
}

.vengage__datePicker {
    /* border: 1px solid rgba(17, 159, 218, 1); */
    min-height: 300px;
    /* max-height: 400px; */


    width: 99%;
    margin-left: 5px;
    /* border-bottom-left-radius:8px ; */
    /* border-bottom-right-radius: 8px; */
    /* border-top-right-radius: 8px; */
    /* background-color: #F2F1F0; */
}



.vengage__dot_pulse {
    position: relative;
    left: -9999px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: gray;
    color: gray;
    box-shadow: 9999px 0 0 -5px #9880ff;
    animation: dotPulse 1.5s infinite linear;
    animation-delay: .25s;
}

.vengage__dot_pulse::before,
.vengage__dot_pulse::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: gray;
    color: gray;
}

.vengage__dot_pulse::before {
    box-shadow: 9984px 0 0 -5px #9880ff;
    animation: dotPulseBefore 1.5s infinite linear;
    animation-delay: 0s;
}

.vengage__dot_pulse::after {
    box-shadow: 10014px 0 0 -5px #9880ff;
    animation: dotPulseAfter 1.5s infinite linear;
    animation-delay: .5s;
}

.vengage__datePicker__oneMonth {
    height: 280px;
    min-height: 310px;
    width: 98%;
    margin-left: 5px;
    border: 1px solid rgba(17, 159, 218, 1);
}

@keyframes dotPulseBefore {
    0% {
        box-shadow: 9984px 0 0 -5px lightgray;
    }

    30% {
        box-shadow: 9984px 0 0 2px lightgray;
    }

    60%,
    100% {
        box-shadow: 9984px 0 0 -5px lightgray;
    }
}

@keyframes dotPulse {
    0% {
        box-shadow: 9999px 0 0 -5px gray;
    }

    30% {
        box-shadow: 9999px 0 0 2px gray;
    }

    60%,
    100% {
        box-shadow: 9999px 0 0 -5px gray;
    }
}

@keyframes dotPulseAfter {
    0% {
        box-shadow: 10014px 0 0 -5px gray;
    }

    30% {
        box-shadow: 10014px 0 0 2px gray;
    }

    60%,
    100% {
        box-shadow: 10014px 0 0 -5px gray;
    }
}


@media (max-width: 600px) {

    .vengage__datePicker {
        height: 410px;
        width: 95%;
    }

    .vengage__tabs {
        width: 95%;


    }
}