.vengage__reappointment {
  position: absolute;
  top: 160px;
  background: #ffffffe6;
  padding: 12px;
  text-align: center;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  font-weight: normal !important;
}
/* .options{
    border: 1px solid lightgray;
    padding: 10px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  } */
.vengage__reappointment .vengage__options {
  margin: 15px 0;
  position: relative;
  top: 31%;
  transform: translateY(-50%);
}
.vengage__options {
  /* background-color: #f1f3f5; */
}

.vengage__options__container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 30px;
}
.vengage__restart {
  text-align: left;
}

@media (max-width: 600px) {
  .vengage__reappointment {
    font-size: 14px;
  }
  .vengage__options {
    margin: 0px !important;
  }
}
