.vengage__timeOutNotification__background {
  background-color: lightgray;
  display: flex;
  align-items: center;
}

.vengage__timeOutNotification {
  position: absolute;
  top: 95px;
  background: #ffffffe6;
  padding: 12px;
  text-align: left;
  border: 1px solid lightgray;
  /* height: fit-content; */

  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  opacity: 1;
}

.vengage__timeOut__message {
  /* position: absolute;  */
  /* left: -2px; */
  /* top: 100px; */
  /* background-color: lightgray; */
  margin-top: 40%;
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 10px;

  /* padding: auto 10px; */
}

.vengage__continue__session {
  padding: 10px;
  background-color: #a9d18e;
  border-radius: 5px;
  outline: none;
  border: 1px solid lightgray;
  cursor: pointer;
  /* color: white; */
}

@media (max-width: 600px) {
  .vengage__timeOut__message {
    font-size: 13px;
    left: 21px;
  }
}
