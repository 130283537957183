.fileSend__error {
    position: absolute;
    top: 5px;
    color: black;
}

/* html {
  font-size: 1rem !important;
} */

.restartButton {
    cursor: pointer;
    font-size: 13px !important;
    /* border-bottom: 2px solid !important; */
    width: fit-content !important;
    padding: 2px 5px 2px 5px !important;
    border-radius: 5px !important;
    outline: none !important;
    border: 1px solid lightblue;
    margin-bottom: 4px;
    /* Overriding the css  */
    color: #000 !important;
    background-color: #efefef !important;
    font-weight: normal !important;
}

/* on hover over the "Restart Chat" button, color changes the button */
.restartButton:hover {
    background-color: #81aad0;
    color: white;
}

.react-chatbot-kit-chat-header__centerName {
    display: flex;
    align-items: center;
    font-size: 13px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.connection__status {
    position: absolute;
    right: 80px;
    top: 0px;
}

.menubar__websocketReconnect {
    background-color: orange;
    font-size: 12px;
    position: absolute;
    right: 25px;
    top: -10px;
    /* color: red; */

    /* border-radius: 5px; */
}

.menubar__websocketConnected {}

.websocketConnected {
    position: absolute;
    right: -4px;
    top: -5px;
    z-index: 100;
    width: 15px;
    height: 15px;
    font-size: 15px;
    background-color: limegreen !important;
    border-radius: 50%;
}

.menubar__networkDisconnected {
    /* color: rgb(248, 86, 37); */
}

.title__N_close_icon {
    display: flex;
    align-items: center;
}

.close__chatbot {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    background-color: white;
    position: relative;
    /* bottom: 40px; */
    /* top: 4px;
  left: 2px; */
    z-index: 9999;
    font-size: 20px;
    /* right: 40px;  */
    box-shadow: 0px 0px 6px rgb(66 66 66 / 49%);
    cursor: pointer;
    font-size: 23px;
    color: black;
    margin: auto;
    padding: 1px;
    text-align: center;
    line-height: 1em;
}


.circle {
    z-index: 100;
    width: 10px;
    height: 10px;
    font-size: 15px;
    background-color: limegreen !important;
    border-radius: 50%;
}

.circle-orange {
    width: 10px;
    height: 10px;
    font-size: 15px;
    background-color: orange;
    border-radius: 50%;
}


.chatbot-title {
    font-size: 18px !important;

    /* display: flex ;
  flex-direction: column;

  justify-content: space-between; */
}


.close__chatbot {
    display: none;
}

.chatbot-icon {
    position: relative;
    display: flex;
    align-items: center;
    gap:15px ;
}

@media (max-width: 600px) {
    .fileSend__error {
        top: 21px;
        left: 41px;
        /* right:0px */
    }

    .chatBotHeader__refreshIcon {
        /* padding-right: 15px; */
        position: absolute;
        /* top:0px; */
        right: 25px;
        bottom: -3px;
    }

    /* on mobole view ,header's css was distrubing so i added this css */

    .titleName {
        font-size: 17px !important;
        margin: 0px 0px 0px 6px !important;
        padding-top: 3px !important;
    }

    .chatbot-title {
        /* margin-top: 10px !important; */
        /* margin-bottom: -10px !important; */
        /* margin-left: 30px; */

    }

    .restartButton {
        font-size: 13px !important;
    }

    .restart_buttonContainer {
        margin-left: 6px;
        margin-top: 2px;
    }

    .react-chatbot-kit-chat-header__centerName {
        margin-left: 2px;
        font-size: 12px;
    }

    .connection__status {
        /* right: 84px; */
        top: 14px;
    }

    .websocketConnected {
        top: 10px;
        right: 2px;
        width: 12px;
        height: 12px;


    }

    .close__chatbot {
        display: block;
    }

    .menubar__websocketReconnect {
        top: 23px;
        right: 64px;
        font-size: 11px;
    }
}