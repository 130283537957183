.options {
  margin: 15px 0;
 
}

.options__container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}


@media (max-width: 600px) {
  .button__container{
    width: 100%;
  }
 
  .options{
    width: 100% !important;
  }
  .options__container{
    width: 100%;
  }
}
