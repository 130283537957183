.vengage__options {
  margin: 15px 0;
  margin-left: 10px;
}

.vengage__options__header {
  font-size: 1rem;
  font-weight: normal;
}

.vengage__options__container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 16px;
}
.vengage__btn__primary {
  font-weight: 400;
  color: #212529 !important;
  border: none !important;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #a9d18e !important;
  border: 1px solid transparent;
  padding: 0.35rem 0.75rem;
  font-size: 14px;
  line-height: 1.5;
  border-radius: 0.25rem;
  flex-grow: 1;
  margin-left: 4px;
  margin-top: 4px;
  cursor: pointer;
  margin-right: 4px;
  opacity: 1;
  transition: opacity 0.3s;
}
.vengage__btn__primary:hover,
.vengage__btn__primary:focus,
.vengage__btn__primary:active {
  opacity: 0.8;
}

.vengage__option__item {
  padding: 8px 15px;
  border: 1px solid #2898ec;
  border-radius: 25px;
  color: #2898ec;
  font-size: 0.9rem;
  margin: 3px;
}
.vengage__calendar__block {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 14px;
}

.vengage__calendar__block .vengage__option__item {
  padding: 4px 8px;
  border: 1px solid #7b7b7b;
  border-radius: 4px;
  /* color: #7b7b7b; */
  color: black;
  font-size: 14px;
  margin: 2px;
  margin-left: 4px;
  margin-right: 4px;
  flex: 0 0 15.333333%;
  text-align: center;
  cursor: pointer;
  background: #ffff;
  transition: background 0.3s;
}
.vengage__calendar__block .vengage__option__item:hover,
.vengage__calendar__block .vengage__option__item:focus {
  background: #e7e7e7;
}
.vengage__calendar__block .vengage__option__item.active {
  background: #e7e7e7;
}
.vengage__overview__block {
  box-shadow: 0 1px 5px rgb(0 0 0 / 20%);
  padding: 10px;
}
.vengage__overview__row {
  display: flex;
  border-bottom: 1px solid #e9e9e9;
}
.vengage__overview__row .vengage__item {
  flex-grow: 1;
  font-size: 14px;
  /* padding: 3px; */
  padding-top: 6px;
}
.vengage__overview__row .vengage__item:first-child {
  background: #ffffff;
  flex-basis: 35%;
  flex-grow: 0;
  /* color: #7b7b7b; */
  color: black;
  border-right: 1px solid #f2ebeb;
  margin-right: 6px;
  padding-bottom: 6px;
  padding-top: 6px;
  font-weight: 450;
}

.vengage__booking__overviewCenterName {
  font-size: 14px;
  padding-right: 80px;
  border-right: 1px solid lightgrey;
  display: flex;
  align-items: center;
  font-weight: 450;
}
.vengage__booking__overview {
  font-size: 14px;
  padding-right: 62px;
  border-right: 1px solid lightgrey;
  display: flex;
  align-items: center;
  font-weight: 450;
}

@media (max-width: 600px) {
  .vengage__btn__primary {
    padding: 5px 0px;
  }
  .vengage__booking__overview {
    padding-right: 52px;
  }
  .vengage__booking__overviewCenterName {
    padding-right: 48px;
  }
}
