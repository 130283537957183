.bookingDetails,
.bookingDetails {
  width: 100%;
}
.overViewDetail,
.centerDetail {
  display: flex;
  border-bottom: 1px solid lightgray;
  /* padding: 10px; */
  font-size: 14px;
  height: 30px;
  /* padding-top: 5px; */
}
.centerDetail {
  height: 40px;
  font-size: 14px;
}
.overViewDetail__title{
  text-transform: capitalize;

}

.overViewDetail__title,
.centerDetail__title {
  width: 30%;
  /* border-right: 1px solid lightgray; */
  height: fit-content;
  font-weight: 500;
  padding-top: 6px;
  display: flex;
  align-items: center !important;
}
.centerDetail__title {
  padding-top: 10px;
}
.overViewDetail__value,
.overViewDetail__value_boldFont {
  width: 70%;
  border-left: 1px solid lightgray;
  padding-left: 5px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  /* padding-top: 5px; */
}
.overViewDetail__value_boldFont {
  font-weight: bold;
  display: flex;
  align-items: center;
}

@media (max-width: 600px) {
  .overViewDetail {
    font-size: 12px;
  }
  .overViewDetail__value_boldFont,.overViewDetail__value{
    font-size: 13px;
  }
}
