input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
}

/**************************************************/
.vengage__inputChange {
  border: none;
  outline: none;
}

.vengage__options {
  margin: 15px 0;
  margin-left: 30px;
}

.vengage__options__container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 16px !important;
}

.vengage__option__item {
  padding: 8px 15px;
  border: 1px solid #2898ec;
  border-radius: 25px;
  color: #2898ec;
  font-size: 0.9rem;
  margin: 3px;
}

.vengage__option__item {
  padding: 4px 8px;
  border: 1px solid #7b7b7b;
  border-radius: 4px;
  /* color: #7b7b7b; */
  color: black;
  font-size: 14px;
  margin: 2px;
  margin-left: 4px;
  margin-right: 4px;
  flex: 0 0 15.333333%;
  text-align: center;
  cursor: pointer;
  background: #ffff;
  transition: background 0.3s;
}
.vengage__option__item:hover,
.vengage__option__item:focus {
  background: #e7e7e7;
}
.vengage__option__item.active {
  background: #e7e7e7;
}
.vengage__overview__block {
  box-shadow: 0 1px 5px rgb(0 0 0 / 20%);
  padding: 10px;
}
.vengage__overview__row {
  display: flex;
  border-bottom: 1px solid #e9e9e9;
}
.vengage__overview__row .vengage__item {
  flex-grow: 1;
  font-size: 14px;
  /* padding: 3px; */
  padding-top: 6px;
}
.vengage__overview__row .vengage__item:first-child {
  background: #ffffff;
  flex-basis: 35%;
  flex-grow: 0;
  /* color: #7b7b7b; */
  color: black;
  border-right: 1px solid #f2ebeb;
  margin-right: 6px;
}

.vengage__itemDate {
  /* margin-right: 25px; */
  padding-right: 52px;
  color: #7b7b7b;
  font-size: 14px;
  border-right: 1px solid lightgray;
}
