.vengage__options {
  text-align: center;
    /* display: flex; */
    margin-top: 10px;
    /* flex-wrap: wrap; */
    /* flex-direction: column; */
    /* justify-content: center; */
  }
  
  .vengage__option {
    text-align: center;
    width: 73%;
    border-radius: 15px;
    padding: 8px;
    padding-left: 10px;
    border: 1px solid #173e3f;
    color: #1d1d1d;
    font-size: 0.7rem !important;
    margin: 4px;
    margin-left: -14px;
    background: transparent;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    line-height: normal !important;
  }
  .vengage__option:hover,
  .vengage__option:focus {
    background: #ececec;
  }
  .vengage__buttonImage {
    /* width: 40px;
    margin-right: 8px; */
    width: 100%;
    height: 100%;
    display:flex;
    justify-content:center;
    align-items:center;
    overflow:hidden;
    object-fit:contain;

  }
  .vengage__txt {
    font-weight: 700;
    font-size: 16px;
  }
  .vengage__subtxt {
  }

  .vengage__flag{
    width: 40px;
    height: 30px;
    margin-right: 8px;
    
  }
  
  @media (max-width: 600px) {
    .vengage__option {
      font-size: 12px;
    }
    .vengage__txt {
      font-size: 14px;
    }
    .vengage__buttonImage {
      width: 30px;
    }
  }
  