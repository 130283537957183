.custom_tab_container{
    border:1px solid lightgrey;
    border-radius:3px;
    padding:2px 5px;
    
    font-size:13px;
    cursor: pointer;
    margin-right: 5px;
    }
    
    .custom_tab_container:hover{
        background-color: lightgray;
    }
    
    .vengage__dot_pulse {
        position: relative;
        left: -9999px;
        text-align: center;
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background-color: gray;
        color: gray;
        box-shadow: 9999px 0 0 -5px #9880ff;
        animation: dotPulse 1.5s infinite linear;
        animation-delay: .25s;
      }
      
      .vengage__dot_pulse::before, .vengage__dot_pulse::after {
        content: '';
        display: inline-block;
        position: absolute;
        top: 0;
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background-color: gray;
        color: gray;
      }
      
      .vengage__dot_pulse::before {
        box-shadow: 9984px 0 0 -5px #9880ff;
        animation: dotPulseBefore 1.5s infinite linear;
        animation-delay: 0s;
      }
      
      .vengage__dot_pulse::after {
        box-shadow: 10014px 0 0 -5px #9880ff;
        animation: dotPulseAfter 1.5s infinite linear;
        animation-delay: .5s;
      }
    
    
      @keyframes dotPulseBefore {
        0% {
          box-shadow: 9984px 0 0 -5px lightgray;
        }
        30% {
          box-shadow: 9984px 0 0 2px lightgray;
        }
        60%,
        100% {
          box-shadow: 9984px 0 0 -5px lightgray;
        }
      }
      
      @keyframes dotPulse {
        0% {
          box-shadow: 9999px 0 0 -5px gray;
        }
        30% {
          box-shadow: 9999px 0 0 2px gray;
        }
        60%,
        100% {
          box-shadow: 9999px 0 0 -5px gray;
        }
      }
      
      @keyframes dotPulseAfter {
        0% {
          box-shadow: 10014px 0 0 -5px gray;
        }
        30% {
          box-shadow: 10014px 0 0 2px gray;
        }
        60%,
        100% {
          box-shadow: 10014px 0 0 -5px gray;
        }
      }
      
      
    
    
    /* 
    
      .vengage__dot_pulse {
        color:gray;
        font: 300 4em/150% Impact;
        text-align: center;}
      
      
      
      .vengage__dot_pulse:after {
        content: ' .';
        animation: dots 1s steps(8, end) infinite;}
      
      @keyframes dots {
        0%, 20% {
          color: rgba(0,0,0,0);
          text-shadow:
            .25em 0 0 rgba(0,0,0,0),
            .5em 0 0 rgba(0,0,0,0);}
        40% {
          color: white;
          text-shadow:
            .25em 0 0 rgba(0,0,0,0),
            .5em 0 0 rgba(0,0,0,0);}
        60% {
          text-shadow:
            .25em 0 0 white,
            .5em 0 0 rgba(0,0,0,0);}
        80%, 100% {
          text-shadow:
            .25em 0 0 white,
            .5em 0 0 white;}}
       */