.vengage__reappointment {
  position: absolute;
  top: 160px;
  background: #ffffffe6;
  padding: 12px;
  text-align: center;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
}
.vengage__restartPopUp {
  border: 2px solid #2898ec;
  padding: 10px 10px 30px 10px;
}
.vengage__reappointment .vengage__options {
  margin: 15px 0;
  position: relative;
  top: 31%;
  transform: translateY(-50%);
}

.vengage__options__container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 30px;
}
.vengage__restart {
}
