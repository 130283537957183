.vengage__options {
  display: flex;
  margin-top: 10px;
  flex-wrap: wrap;
  flex-direction: column;
  padding-left: 30px;
  max-width: 82%;
}

.vengage__option {
  border-radius: 6px;
  padding: 0;
  border: 1px solid #7386a2;
  color: #1d1d1d;
  font-size: 14px;
  margin: 4px 4px;
  background: transparent;
  display: inline-flex;
  cursor: pointer;
  overflow: hidden;
} 
.option {
  border-radius: 6px;
  padding: 0;
  border: 1px solid #7386a2;
  color: #1d1d1d;
  font-size: 14px;
  margin: 4px 4px;
  background: transparent;
  /* display: inline-flex; */
  cursor: pointer;
  /* overflow: hidden; */
}
.vengage__option:hover,
.vengage__option:focus {
  background: #ececec;
}
.vengage__buttonImage {
  width: 54px;
  margin-right: 8px;
}
.vengage__txt {
  font-weight: 700;
  font-size: 14px;
  padding-top: 4px;
}
.vengage__subtxt {
  padding-bottom: 9px;
}
.vengage__citySelection__srNo {
  background: #119fda;
  border: 50%;
  width: 14px;
  height: 14px;
  font-size: 12px;
  color: white;
}

@media (max-width: 600px) {
  .vengage__option {
    font-size: 12px;
  }
  .vengage__buttonImage {
    width: 40px;
  }
}
