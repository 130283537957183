.vengage__options {
  margin: 15px 0;
}

.vengage__options__container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.vengage__btn__primary {
  font-weight: 400;
  color: #212529 ;
  border: none !important;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #a9d18e ;
  border: 1px solid transparent;
  padding: 0.35rem 0.75rem;
  font-size: 14px;
  line-height: 1.5;
  border-radius: 0.25rem;
  flex-grow: 1;
  margin-left: 4px;
  cursor: pointer;
  margin-right: 4px;
  opacity: 1;
  transition: opacity 0.3s;
}
.vengage__btn__primary:hover,
.vengage__btn__primary:focus,
.vengage__btn__primary:active {
  opacity: 0.8;
}

@media (max-width: 600px) {
  .vengage__btn__primary {
    padding: 5px 0px;
  }
}
