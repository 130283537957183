.ForwardRef-root-1 {
    height: 35px !important;
    border-top: none !important;
    border-radius: 0px !important;

    border: 1px solid black !important;
    /* width: 98% !important; */
    box-shadow: none !important;
    font-size: 12px !important;
}

.jss1 {
    border: 1px solid black !important;
}

.app-chatbot-container {
    margin: 40px 0;
    display: flex;
    justify-content: center;
    position: fixed;
    right: 40px;
    bottom: 10px;
    z-index: 9999;
    box-shadow: 7px 14px 27px rgb(66 66 66 / 47%);
    border-radius: 20px;
    overflow: hidden;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
    color: black;

}

.app-chatbot-button {
    width: 50px;
    height: 50px;
    border-radius: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    background-color: #efefefa3;
    position: fixed;
    bottom: 40px;
    z-index: 9999;
    right: 40px;
    box-shadow: 0px 0px 6px rgb(66 66 66 / 49%);
    cursor: pointer;
}

.app-chatbot-content .app-chatbot-button {
    right: 20px;
    background-color: #fff;
}

.app-chatbot-content .app-chatbot-button-icon {
    width: 18px;
    height: 18px;
    fill: #7b7b7b;
}

.app-chatbot-button-title {
    width: fit-content;
    position: fixed;
    background: rgb(66, 63, 63) !important;
    color: white !important;
    font-size: 14px !important;
    font-weight: 700;
    /* right: 23px; */
    padding: 3px;
    padding-left: 8px;
    padding-right: 8px;
    /* padding-top: 6px; */
    /* padding-bottom: 6px; */
    z-index: 9999;
    bottom: 11px;
    /* text-align: center; */
    vertical-align: middle;
}

.app-quickstart-button {
    color: #fff;
    border: 1px solid #fff;
    text-decoration: none;
    padding: 10px;

    display: block;
    margin: 30px auto;
    width: 150px;
}

.app-chatbot-button-icon {
    width: 100%;

    /* width: 60px;
  height: 60px; */
    border-radius: 50%;
    position: absolute;
    top: 1px;
    left: 0px;

    /* width: 25px;
  height: 25px; */
    fill: #fff;
}

.vengage-chatbot-icon {
    display: flex;
    justify-content: center;

}

.vengage-chatbot-icon:hover {
    box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;

}

.app-header {
    color: #fff;
}

.app-paragraph {
    color: #fff;
    max-width: 400px;
    margin: 0 auto;
}

.app-overview {
    margin-top: 5rem;
}

.app-overview-gist-container {
    max-width: 500px;
    margin: 2rem auto;
}

.app-gists-link {
    background-color: #53a88b;
    color: #fff;
    padding: 10px;
    border-radius: 3px;
    text-decoration: none;
    width: 250px;
    font-size: 1.1rem;
    margin: 0.5rem 0;
}

.app-gists-link-dark {
    background-color: transparent;
    color: #fff;
    border: 1px solid #fff;
    padding: 10px;
    border-radius: 3px;
    text-decoration: none;
    width: 250px;
    font-size: 1.1rem;
    margin: 0.5rem 0;
    transition: background-color 0.4s ease;
}

.app-gists-link-dark:hover {
    background-color: #fff;
    color: rgb(51, 96, 124);
}

.gist {
    max-width: 100%;
}

.chatbot-header-content-primary {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.chatbot-header-content-primary .chatbot-title {
    font-weight: 500;
    font-size: 16px;
}

.chatbot-header-content-primary .chatbot-icon>img {
    width: 60px;
    height: 60px;
    border-radius: 100%;
}

.app__connected {
    font-size: 25px;
    color: green;
    padding-right: 5px;
    position: absolute;
    bottom: -15px;
    left: 20px;
    display: flex;
    align-items: center;
}

.app__noResponse__error {

    position: absolute;
    bottom: 49px;
    left: 10px;
    color: #ca4335;
    z-index: 100;
    font-size: 14px;
}

.vengage__chatbot {
    border: 1px solid;
    /* background-color: lightgreen; */

}

@media (max-width: 600px) {

    /* .app-chatbot-container {
  } */
    .app-chatbot-content {
        /* width: 500px; */
        margin: -21px !important;
    }

    .app-chatbot-button {
        width: 36px !important;
        height: 36px !important;
    }

    .app__connected {
        bottom: -25px;
        left: 40px;
    }

    .chatbot-header-content-primary .chatbot-title {
        margin-left: 17px !important;
    }

    .chatbot-header-content-primary .chatbot-icon>img {
        margin-top: 10px;
        margin-right: 13px;
        width: 50px;
        height: 50px;
    }

    .app-chatbot-container {
        right: 22px;
        width: 350px;
        line-height: normal;
    }

    .hide__closeIcon {
        display: none;
    }

    .app__noResponse__error {
        font-size: 12px;
        bottom: 31px;
    }


}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

table td,table th {
    word-break: unset;
}
table tr:last-child th:before {
    background-color: unset;
}