.vengage__fileUpload {
  border: 1px solid lightgray;
  width: 250px;
  margin-top: 15px;
}
.vengage__fileUpload__button {
  display: flex;
  justify-content: space-between;
}

.vengage__fileUpload__showpreview {
  display: flex;
  justify-content: center;
  padding: 10px;
}
.vengage__btn__primary {
  font-weight: 400 !important;
  color: #212529;
  border: none !important;
  text-align: center;
  vertical-align: middle !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #a9d18e;
  border: 1px solid transparent;
  padding: 0.35rem 0.75rem !important;
  font-size: 14px !important;
  line-height: 1.5 !important;
  border-radius: 0.25rem !important;
  flex-grow: 1;
  margin-left: 4px !important;
  cursor: pointer;
  margin-right: 4px !important;
  opacity: 1;
  transition: opacity 0.3s;
}

.retry__button{
  margin-top: 10px;
}

.button{
  background: #119FDA;
  border: 1px solid lightgray;
  border-radius: 2px;
  color: white;
 
  padding: 3px 10px 3px 10px;
}
.button:hover{
  cursor: pointer;
}

.network__error{
  color: red;
  font-size: 13.5px;
  margin-top: 10px;
}