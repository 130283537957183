.vengage__askConfirmation {
  position: absolute;
  top: 160px;
  background: #ffffffe6;
  padding: 12px;
  /* text-align: center; */
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
}
.vengage__restartPopUp {
  border: 2px solid #2898ec;
  padding: 10px 10px 30px 10px;
}
.vengage__askConfirmation .vengage__options {
  margin: 15px 0;
  position: relative;
  top: 31%;
  transform: translateY(-50%);
}
.vengage__options{
  /* background-color: rgba(241, 238, 238, 0.966); */
  background-color: white;
}

.vengage__options__container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 30px;
}
.vengage__restartText {
  margin-bottom: 0px !important;
}
.vengage__orText {
  margin-bottom: 0px !important;
}
.vengage__cancelText {
  margin-top: 0px !important;
}
.vengage__restart {
}
.vengage__clickrestartText {
  margin-top: 0px;
  margin-bottom: 0px;
}

.vengage__note{
  font-size: 13px;
  font-style: italic;
}

@media (max-width: 600px) {
  .vengage__options,
  .vengage__restartPopUp {
    margin-left: 13px !important;
  }
}
