input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
}

/**************************************************/
.vengage__inputChange {
  /* width: 125px !important; */
  outline: none !important;
  padding: 3px !important;
}

.vengage__options {
  margin: 15px 0;
  margin-left: 15px;
}

.vengage__options-header {
  font-size: 1rem;
  font-weight: normal;
}

.vengage__options__container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 16px;
}

.vengage__option__item {
  padding: 8px 15px;
  border: 1px solid #2898ec;
  border-radius: 25px;
  color: #2898ec;
  font-size: 0.9rem;
  margin: 3px;
}
.vengage__calendar__block {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 14px;
}

.vengage__calendar__block .vengage__option__item {
  padding: 4px 8px;
  border: 1px solid #7b7b7b;
  border-radius: 4px;
  /* color: #7b7b7b; */
  color: black;
  font-size: 14px;
  margin: 2px;
  margin-left: 4px;
  margin-right: 4px;
  flex: 0 0 15.333333%;
  text-align: center;
  cursor: pointer;
  background: #ffff;
  transition: background 0.3s;
}
.vengage__calendar__block .vengage__option__item:hover,
.vengage__calendar__block .vengage__option__item:focus {
  background: #e7e7e7;
}
.vengage__calendar__block .vengage__option__item.active {
  background: yellow !important;
}
.vengage__overview__block {
  box-shadow: 0 1px 5px rgb(0 0 0 / 20%);
  padding: 10px;
}
.vengage__overview__row {
  display: flex;
  border-bottom: 1px solid #e9e9e9;
  padding-bottom: 5px !important;
}
.vengage__overview__row .vengage__item {
  flex-grow: 1;
  font-size: 14px;
  /* padding: 3px; */
  padding-top: 6px;
}
.vengage__overview__row .vengage__item:first-child {
  background: #ffffff;
  flex-basis: 35%;
  flex-grow: 0;
  /* color: #7b7b7b; */
  color: black;
  border-right: 1px solid #f2ebeb;
  margin-right: 6px;
}

.vengage__itemDate {
  /* margin-right: 25px; */
  padding-top: 8px !important;
  padding-right: 51px !important;
  color: black;
  font-weight: 450;
  padding-left: 1px;
  font-size: 14px;
  border-right: 1px solid lightgray;
}
.vengage__inputDate {
  width: 35px !important;
  outline: none !important;
  margin-left: 6px !important;
  padding: 2px !important;
  text-align: center !important;
}
.vengage__inputMonth {
  width: 35px !important;
  outline: none !important;
  margin-left: 6px !important;
  padding: 2px !important;
  text-align: center !important;
}

.vengage__inputYear {
  width: 60px !important;
  outline: none !important;
  padding: 2px !important;
  text-align: center !important;
}

.vengage__inputCode {
  width: 35px !important;
  border: 1px solid lightgrey !important;
  outline: none !important;
  text-align: center;
}

.vengage__inputPhoneNo {
  border: 1px solid lightgrey !important;
  width: 119px !important;
  margin-left: 11px !important;
  outline: none !important;
  padding-left: 10px !important;
}
.vengage__itemDateInput {
  padding-top: 8px 0px 5px 0px !important;
  display: flex !important;
  /* width: 137px !important; */
  width: 54%;
  justify-content: space-between !important;
}
.vengage__itemDate__input {
  border: none;
  outline: none;
}

.insuranceError{
  font-size: 13px;
  margin-bottom: 0px;
  margin-top: 2px;
  color: red;
  
}


@media (max-width: 600px) {
  .vengage__itemDate {
    padding-right: 29px !important;
    padding-top: 9px !important;
    padding-bottom: 4px !important;
    padding-left: 3px !important;
  }
  .vengage__itemDate__input {
    padding-top: 4px !important;
    border: none;
    outline: none;
  }
  .select__insuranceType{
    width: 62% !important;
  }
  .vengage__itemDateInput{
    width: 64% !important;
  }
}
