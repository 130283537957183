.react-chatbot-kit-user-chat-message-container {
    display: flex;
    margin: 15px 0;
    justify-content: flex-end;
}

.react-chatbot-kit-user-avatar-container {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-left: 12.5px;
    background-color: #3d4e8d;
    display: flex;
    justify-content: center;
    align-items: center;
}

.react-chatbot-kit-user-avatar-icon {
    fill: #fff;
    width: 15px;
    height: 15px;
}

.react-chatbot-kit-user-chat-message {
    background-color: #f1f1f1;
    padding: 10px;
    border-radius: 5px !important;
    font-size: 14px;
    color: #585858;
    font-weight: medium;
    position: relative;
    text-align: left;
}

.react-chatbot-kit-user-chat-message-arrow {
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 8px solid #f1f1f1;
    position: absolute;
    right: -7px;
    top: 13px;
}

.react-chatbot-kit-chat-bot-message-container {
    display: flex;
    margin: 15px 0;
    justify-content: flex-start;
}

.react-chatbot-kit-chat-bot-avatar-container {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 1.2rem;
    margin-right: 12.5px;
    background-color: #d8d8d8;
    display: flex;
    justify-content: center;
    align-items: center;
}

.react-chatbot-kit-chat-bot-avatar-icon {
    fill: #494646 !important;
    width: 22.5px !important;
    height: 22.5px !important;
}

.react-chatbot-kit-chat-bot-avatar-letter {
    color: #1d1d1d;
    margin: 0;
    padding: 0;
}

.react-chatbot-kit-chat-bot-message {
    background-color: #2898ec;
    padding: 10px;
    border-radius: 5px;
    font-size: 0.9rem;
    color: #fff;
    font-weight: medium;
    position: relative;
    width: 184.5px;
    margin-left: auto;
    text-align: left;
}

.react-chatbot-kit-chat-bot-message-arrow {
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid #2898ec;
    position: absolute;
    left: -7px;
    top: 13px;
}

.react-chatbot-kit-chat-bot-loading-icon-container {
    height: 17px;
    width: 25px;
}

.chatbot-loader-container {
    display: flex;
    width: 100%;
    justify-content: center;
}

#chatbot-loader #chatbot-loader-dot1 {
    animation: load 1s infinite;
}

#chatbot-loader #chatbot-loader-dot2 {
    animation: load 1s infinite;
    animation-delay: 0.2s;
}

#chatbot-loader #chatbot-loader-dot3 {
    animation: load 1s infinite;
    animation-delay: 0.4s;
}

@keyframes load {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.react-chatbot-kit-chat-inner-container {
    height: 500px;
    background-color: #fff;
    border-radius: 3px;
    margin-bottom: 15px;
    border-radius: 5px;
}

.react-chatbot-kit-chat-header {
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    background-color: #efefef;
    font-family: Arial;
    display: flex;
    align-items: center;
    font-size: 0.85rem;
    color: #514f4f;

    /* header's bottom padding decreased from 12.5px to 8px */
    padding: 12.5px 12.5px 1px 12.5px;

    font-weight: bold;
}

.react-chatbot-kit-chat-input-container {
    position: absolute !important;
    bottom: 0 !important;
    width: 100% !important;
    display: flex !important;
}

.react-chatbot-kit-chat-message-container {
    padding: 0 17.5px 10px 17.5px;
    overflow: scroll;
    height: 424px;
}

.react-chatbot-kit-chat-input {
    width: 100% !important;
    padding: 12.5px !important;
    border: none !important;
    font-size: 0.85rem !important;
    border-top: 1px solid #d8d8d8 !important;
    border-bottom-left-radius: 5px !important;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif !important;
    line-height: normal !important;
}

.react-chatbot-kit-chat-input-form {
    width: 100% !important;
    display: flex !important;
}

.react-chatbot-kit-chat-input::placeholder {
    color: #585858 !important;
}

.react-chatbot-kit-chat-btn-send {
    background-color: #2898ec ;
    width: 100px !important;
    /* send button's padding made 0px because client site's button overriding the our button'spadding */
    padding: 0px !important;
    border: none !important;
    border-radius: 0px !important;

    color: #fff !important;
    /* border-bottom-right-radius: 5px; */
    /* background: #fff; */
    box-shadow: 0px 0px 5px #00000045 !important;
    /* border-radius: 0; */
    /* max-width: 85%; */
    z-index: 999 !important;
    /* height:42px; */
    /* margin: auto; */
    /* to centerlized the send icon , i added this display property*/
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.react-chatbot-kit-chat-btn-send-icon {
    fill: #fff !important;
    width: 15px !important;
}

.react-chatbot-kit-error {
    background-color: #fff;
    border-radius: 3px;
    padding: 15px;
}

.react-chatbot-kit-error-container {
    width: 260px;
}

.react-chatbot-kit-error-header {
    font-size: 1.3rem;
    color: #1d1d1d;
    margin-bottom: 30px;
}

.react-chatbot-kit-error-docs {
    display: block;
    margin: 25px auto;
    color: rgb(56, 104, 139);
    padding: 8px;
    border: 1px solid rgb(40, 152, 236);
    width: 130px;
    text-align: center;
    text-decoration: none;
    font-size: 1rem;
}

@media (max-width: 600px) {
    .react-chatbot-kit-chat-header {
        /* height: 82px; */
        padding: 22.5px 12.5px 0px 12.5px;
    }

    .react-chatbot-kit-chat-btn-send-icon {
        /* padding-right: 21px; */

        /*to centerlized the send icon , i added this position property*/
        position: absolute !important;
        right: 41px !important;
    }

    .react-chatbot-kit-chat-bot-message {
        font-size: 12px;
    }

    /* .react-chatbot-kit-chat-message-container{
    padding-left: 22px;
    padding-right: 24px;
  } */
}