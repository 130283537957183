.options {
  display: flex;
  margin-top: 10px;
  flex-wrap: wrap;
  flex-direction: column;
  padding-left: 30px;
  max-width: 82%;
}

.option {
  border-radius: 6px;
  padding: 0;
  border: 1px solid #7386a2;
  color: #1d1d1d;
  font-size: 14px;
  margin: 4px 4px;
  background: transparent;
  display: flex;
  cursor: pointer;
  overflow: hidden;
}
.option:hover, .option:focus{
  background: #ececec;
}
.buttonImage{
  width: 54px;
  margin-right: 8px;
}
.txt{
  font-weight: 700;
  font-size: 16px;
  padding-top: 4px;
}
.subtxt{    padding-bottom: 9px;}
.header{
  text-align: center;
  padding-top: 4px;
  padding-bottom: 15px;
  font-weight: 700;
  position: relative;
}
.headerTxt{
  position: relative;
    z-index: 1;
    background: #fff;
    padding-left: 10px;
    padding-right: 10px;
}
.hr{
  position: absolute;
  width: 100%;
  top: 20%;
}