.timeSlot_buttons {
  border: 1px solid rgba(17, 159, 218, 1);
  /* background:rgba(166, 228, 255, .1); */
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* box-shadow: rgba(17, 159, 218, 1) 3px 3px ; */
  /* border-radius: 5px; */
  /* margin-top: 10px; */
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
}

.timeSlot {
  display: flex;
  justify-content: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  /* border: 1px solid lightgray; */
  padding: 3px;
  border-radius: 5px;
  margin-top: 10px;
}
.time {
  border: 1px solid  gray;
  align-items: center;
  padding: 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  margin: 2px;
}

.time:hover {
  background-color:  rgba(221, 219, 219, 0.548);
  cursor: pointer;
  transform: scale(1.06);
  font-weight: 450;
}

.selected_slot {
  border: 1px solid lightgray;
  align-items: center;
  padding: 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  margin: 2px;
  background-color: rgba(255, 255, 0, 1);
  font-weight:450 ;
}

.buttons {
  display: flex;
  justify-content: center;
}
.button {
  border: 1px solid lightgray;
  padding: 7px;
  border-radius: 5px;
  font-size: 15px;
  /* width: 30%; */
  display: flex;
  justify-content: center;
  margin: 8px;
  background-color: #a9d18e;
}
.no_slot_text{
  /* padding-top: 0px; */
  display: flex;
  font-size: 14px;
  padding-bottom: 10px;
  justify-content: center;
}
.button:hover {
  /* background-color: lightgray; */
  cursor: pointer;
  /* border-color: white; */
  /* padding: 2px; */
  /* color: white; */
}
