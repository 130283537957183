.doctorName {
  width: fit-content;
  border-bottom: 1px solid lightgray;
  font-size: 15px;
  margin-bottom: 8px;
}
.slotRange {
  border: 1px solid lightgray;
  font-size: 14px;
  padding: 10px 5px;
  border-radius: 3px;
  margin: 2px;
  align-items: center;
}
.slotRange:hover {
  background-color: lightgray;
  cursor: pointer;
}
.slotRanges {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: center; */
}

.selected_timeRange {
  background-color: #119fda;
  border: 1px solid lightgray;
  font-size: 14px;
  padding: 10px 5px;
  border-radius: 3px;
  margin: 2px;
  align-items: center;
  color: white;
}
